import React from "react";
import { PageLayout, ContactForm } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout className={"hiring-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={12}>
              <Header as={"h1"}>Join our Team</Header>
              <Header textAlign={"center"} as={"h3"}>
                If you're interested in joining our team please submit your
                information below and we'll get in touch with you.
              </Header>
            </Grid.Column>
            <Grid.Column textAlign={"left"} width={8}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  address,
                  birthdate,
                  workExperience,
                  weeksAvailable,
                }) =>
                  `Someone has reached out to you via your website hiring form: Name: ${name}, Email: ${email}, Phone: ${phone}, Address: ${address}, Birthdate: ${birthdate}, Work Experience: ${workExperience}, Weekly Availability: ${weeksAvailable}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "birthdate",
                    displayLabel: "Date Of Birth",
                    placeholder: "Date Of Birth",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    displayLabel: "Previous Work Experience",
                    label: "workExperience",
                    placeholder: "Previous Work Experience",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "weeksAvailable",
                    displayLabel: "Weekly Availability",
                    placeholder: "Weekly Availability",
                    type: "multiSelect",
                    required: true,
                    options: [
                      { label: "10-15 hrs", value: "10-15 hrs" },
                      { label: "15-20 hrs", value: "15-20 hrs" },
                      { label: "25+ hrs", value: "25+ hrs" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
